import React, { useContext, useState, useEffect } from "react";
import { MenuContext } from "../../context";
import ChevronLeft from "../../assets/chevron-left.inline.svg";
import ChevronRight from "../../assets/chevron-left.inline.svg";

const Tabs = ({
  showPagination = false,
  tabs,
  tabContent,
  activeTabIndex = 0,
  setActiveTab,
  additionalClassName,
  onClick,
}) => {
  const menuContext = useContext(MenuContext);

  /* TODO if we will get content remove the comments and ESLint disable */
  /* eslint-disable no-unused-vars */
  const activateTab = (event, index) => event.keyCode === 13 && setActiveTab(index);
  /* eslint-enable no-unused-vars */
  let tabItems = tabs.length;
  const [emptyHash, setEmptyHashh] = useState("#");
  const [windowWidth, setWindowWidth] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth);

      if (windowWidth < 601) {
        const tab = document.querySelector(".tab--selected");
        const tabPosition = tab.offsetLeft;
        const parentWidth = tab.parentNode.offsetWidth;
        const scrollPosition = tabPosition - parentWidth / 2 + tab.offsetWidth / 2;
        tab.parentNode.scrollLeft = scrollPosition;
      }
    }
  }, [windowWidth]);

  if (typeof window !== "undefined") {
    if (window.location.href.indexOf("foreignLivingInUK") > -1) {
      setActiveTab(1);
    } else if (window.location.href.indexOf("acceptForProofAddress") > -1) {
      setActiveTab(1);
    } else if (window.location.href.indexOf("acceptFoProof") > -1) {
      setActiveTab(1);
    } else if (window.location.href.indexOf("remortgagePurchase") > -1) {
      setActiveTab(1);
    } else if (window.location.href.indexOf("additionalBorrowing") > -1) {
      setActiveTab(4);
    } else if (window.location.href.indexOf("newBuildProperties") > -1) {
      setActiveTab(6);
    } else if (window.location.href.indexOf("overpaymentsAllowed") > -1) {
      setActiveTab(8);
    }
  }

  const changeHash = () => {
    if (typeof window !== `undefined`) {
      window.history.pushState("object or string", "Title", `${emptyHash}`);
    }
  };

  return (
    <section className={`${additionalClassName || ""} tabs`} id="main-tab">
      {tabs && tabs.length && (
        <div className="tabs__titles" role="tablist">
          {tabs.map((tab, index) => (
            <button
              className={`tab ${index === activeTabIndex ? "tab--selected" : ""}`}
              key={tab}
              aria-selected={index === activeTabIndex}
              aria-controls={`panel-${index}`}
              id={`tab-${index}`}
              role="tab"
              onKeyDown={(e) => activateTab(e, index)}
              onClick={() => {
                setActiveTab(index);
                setEmptyHashh("");
                changeHash();
                console.log("eben " + tab + " | " + index);
              }}
              inert={menuContext.isMenuOpen ? "true" : null}
            >
              {tab}
            </button>
          ))}
        </div>
      )}
      {/* Tabs content section */}
      <div className="tabs__content">
        {tabContent[activeTabIndex]}
        {showPagination && (
          <nav className="tabs__pagination">
            <ul>
              <li>
                {activeTabIndex >= 1 && (
                  <a
                    href="#steps"
                    onClick={() => {
                      setActiveTab(activeTabIndex - 1);
                    }}
                  >
                    <ChevronLeft /> Previous step
                  </a>
                )}
              </li>
              <li>
                {activeTabIndex < tabItems - 1 && (
                  <a
                    href="#steps"
                    onClick={() => {
                      setActiveTab(activeTabIndex + 1);
                    }}
                  >
                    Next step <ChevronRight />
                  </a>
                )}
              </li>
            </ul>
          </nav>
        )}
      </div>
    </section>
  );
};

export default Tabs;
